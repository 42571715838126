
import React,{useState,useEffect} from "react";
import Sidebar from "../components/common/sideBar";
import { useSelector } from 'react-redux'
import { Outlet,Navigate } from "react-router-dom";
import {TokenRoleDataSelector} from '../redux/selectors/loginSelector'

const AppLayout = (props) => {
    const [currentPath, setCurrentPath] = useState(window?.location?.pathname + window?.location?.search);
    const tokenRoleData=useSelector(TokenRoleDataSelector)
    const isLogin = localStorage.getItem("admin_token") || null;

    useEffect(() => {
      const handleLocationChange = () => {
          setCurrentPath(window?.location?.pathname + window?.location?.search);
      };
      window.addEventListener("popstate", handleLocationChange);
      return () => {
          window.removeEventListener("popstate", handleLocationChange);
      };
  }, []);

    return (<>
      {isLogin?(
        <Sidebar>
           <Outlet />
        </Sidebar>  
      ):(
         <Navigate to={`/login?redirect=${encodeURIComponent(currentPath)}`} replace />
      )
    } 
    </>

    )
};

export default AppLayout;
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import logo1 from "../../assets/styles/images/dashboard.svg";
import logo2 from "../../assets/styles/images/user-gear-solid.svg";
import logo3 from "../../assets/styles/images/logout.svg";
import userLogo from "../../assets/styles/images/users.svg";
import { TokenRoleDataSelector } from "../../redux/selectors/loginSelector";
import {ParticipantTokenRoleDataSelector} from "../../redux/selectors/participantLoginSelector"
import { persistor } from "../../redux/store/index";
import { clearloginData } from "../../redux/slice/loginSlice";
import {clearReducers} from "../../utils/common.fucntion"
import IconAccount from "../../assets/styles/images/icon-account.svg"
import { clearparticipantLoginData } from "../../redux/slice/participantLoginSlice";
import AdminExternalLink from "../externalLinks/adminExternalLinks";
import Modalwrap from "./modal";
import LinkIcon from "../../assets/styles/images/link-button.svg"

const Sidebar = (props) => {
  const { company_id } = useParams();
  const location = useLocation();
  const tokenRoleData = useSelector(TokenRoleDataSelector);
  const ParticipantTokenData=useSelector(ParticipantTokenRoleDataSelector)
  const currentPath = location?.pathname;
  const isOpenSideBar = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  
  const menu = useMemo(
    () => ({
      workshop: {
        icon: "",
        tab: "",
      },
      participant: {
        icon: "",
        tab: "",
      },
      secretariat: {
        icon: "",
        tab: "",
      },
    }),
    []
  );
  const [menuState, setMenuState] = useState(menu);
  const selectionStyle = "selection";

  const updateMenuState = useCallback(
    (section, field) => {
      setMenuState({
        ...menu,
        [section]: {
          [field]: selectionStyle,
        },
      });
    },
    [menu]
  );
  const checkMenu = useCallback(() => {
    if (currentPath.includes("/workshops")) {
      if (isOpenSideBar.current) {
        updateMenuState("workshop", "tab");
      } else {
        updateMenuState("workshop", "icon");
      }
    }
    if (currentPath.includes("/participants")) {
      if (isOpenSideBar.current) {
        updateMenuState("participant", "tab");
      } else {
        updateMenuState("participant", "icon");
      }
    }
    if (currentPath.includes("/users")) {
      if (isOpenSideBar.current) {
        updateMenuState("secretariat", "tab");
      } else {
        updateMenuState("secretariat", "icon");
      }
    }
  }, [currentPath, updateMenuState]);
  useEffect(() => {
    checkMenu();
  }, [checkMenu]);

  const openTab = () => {
    isOpenSideBar.current = true;
    checkMenu();
  };

  const closeTab = () => {
    isOpenSideBar.current = false;
    checkMenu();
  };

  const switchToParticipant=()=>{
    if (ParticipantTokenData?.access_token){
      navigate("/participants/home")
      window.location.reload()
    }   
    else
      navigate("/participants/login");
      window.location.reload()
  }

  const logout = () => {
    //clearReducers(dispatch);
    localStorage.removeItem("admin_token")
    localStorage.removeItem("company_id")
    localStorage.removeItem("authority")
    dispatch(clearloginData());
    if(tokenRoleData?.user?.is_participant){
      localStorage.removeItem("participant_token")
      dispatch(clearparticipantLoginData());
    }
    //persistor.purge();
   navigate("/login");
  };

  const mstyle = {
    class: "externalLinksdetailsModal",
  };
  const closeModal = () => {
    setOpenModal(false);
  };
  const handelOpenModal = () => {
    setOpenModal(true);
  };

  return (
    
      <div id="wrapper" className="d-flex flex-column min-vh-100 grey_bgs">
        <div
          id="side"
          onMouseEnter={openTab}
          onMouseLeave={closeTab}
          className="sideBarBody"
        >
          <Link
            to={`/companies/${company_id}/workshops`}
            className={menuState?.workshop?.tab}
          >
            <span className="icn">
              <img
                src={logo1}
                alt="logoimage"
                className={`imsize icon_${menuState?.workshop?.icon}`}
              />
            </span>
            ワークショップ
          </Link>

          <Link
            to={`/companies/${company_id}/participants?page=1`}
            className={menuState?.participant?.tab}
          >
            <span className="icn">
              <img
                src={userLogo}
                alt="ParticipantLogo"
                className={`imsize icon_${menuState?.participant?.icon}`}
              />
            </span>
            参加者
          </Link>
          {tokenRoleData?.authority === 1 || tokenRoleData?.authority === 4 ? (
            <Link
              to={`/companies/${company_id}/users`}
              className={menuState?.secretariat?.tab}
            >
              <span className="icn">
                <img
                  src={logo2}
                  alt="secretariteTabimage"
                  className={`imsize icon_${menuState?.secretariat?.icon}`}
                />
              </span>
              事務局
            </Link>
          ) : (
            ""
          )}
          <div className="footer-side"  >
         {tokenRoleData?.is_participant &&<Link to={""}  onClick={switchToParticipant}>
              <span className="icn" >
                <img src={IconAccount} className="imsize" alt="" />
              </span>
              ユーザー画面に移動
            </Link>}

            <Link to={""}  onClick={logout}>
              <span className="icn" >
                <img src={logo3} className="imsize" alt="" />
              </span>
              ログアウト
            </Link>

            <hr className="sidebar-hr" />

            <Link onClick={handelOpenModal} >
            <span className="icn" >
                <img src={LinkIcon} className="imsize" alt="" />
              </span>
            組織開発お役立ちコンテンツ
            </Link>
            
          </div>
        </div>
        <div id="content-wrapper">
          <div id="content">{props.children}</div>
        </div>

        <Modalwrap
        open={openModal}
        close={closeModal}
        component={<AdminExternalLink close={closeModal} />}
        style={mstyle}
      />

      </div>
    
  );
};

export default Sidebar;
